<template>
  <div class="page-container">
    <AppChildPageHeader
      :title="pageTitle"
      :breadcrumbs="breadcrumbs"
    ></AppChildPageHeader>
    <AppSmartList
      :headers="headers"
      :actions="actions"
      :canCreate="true"
      :newRecordPage="newRecordPage"
      :editRecordPage="editRecordPage"
    ></AppSmartList>
  </div>
</template>

<script>
import AppChildPageHeader from '@/components/base/AppChildPageHeader.vue'
import AppSmartList from '@/components/base/AppSmartList.vue'
import { ROUTE_NAME, DATA_TABLE_LAYOUT } from '@/constants'
import { i18nHelper } from '@/utils'
import {
  USER_GET_USERS,
  USER_INITIAL_GET_USERS_STATE
} from '@/store/user.module'

export default {
  name: 'UserList',
  components: {
    AppChildPageHeader,
    AppSmartList
  },
  data: () => ({
    pageTitle: 'user',
    breadcrumbs: [
      {
        text: 'home',
        disabled: false,
        to: { name: ROUTE_NAME.HOME }
      },
      {
        text: 'user',
        disabled: true
      }
    ],
    headers: [
      {
        text: 'no',
        value: 'no'
      },
      {
        text: 'username',
        value: 'username'
      },
      {
        text: 'name',
        value: 'name'
      },
      {
        text: 'updatedAt',
        value: 'updatedAt'
      },
      {
        text: 'createdAt',
        value: 'createdAt'
      },
      {
        text: 'actions',
        value: 'actions',
        sortable: false,
        layout: DATA_TABLE_LAYOUT.APP_TABLE_ACTIONS,
        actions: ['edit']
      }
    ],
    actions: {
      getList: {
        module: 'user',
        state: 'users',
        action: USER_GET_USERS,
        title: i18nHelper.getMessage('text.getUsers')
      },
      initialGetListState: USER_INITIAL_GET_USERS_STATE
    },
    newRecordPage: ROUTE_NAME.USER_NEW,
    editRecordPage: ROUTE_NAME.USER_DETAILS
  })
}
</script>

<style></style>
